/*
    START - Suggested changes for the entire website
*/

/* Allow clicking through the header shadow, so it doesn't block input. */
.header-shadow {
    pointer-events: none;
    max-width: 100%;
}

.header {
    z-index: 202;
    top: 0;
}

/* Center content block has a max width of 100%, to allow for responsive pages.
   Only useful if the content is also responsive. */
.center {
    max-width: 100%;
    width: 1100px;
}

.content-container .center,
.page-header-container .center {
    max-width: 1100px;
    width: calc(100% - 32px);
}

.page-header-container .center h1 {
    /*margin: 0px 16px;*/
}

.configurator-page-header {
    /*padding-top: calc(121px + 50px);*/
    width: 100%;
}

/* Leave a bit of padding on each side so that content won't be
   squished to the sides of the screen on smaller devices. */
.content-blok {
    max-width: 100%;
    position: relative;
}

.breakline {
    width: 1100px;
    max-width: 100%;
}

/*
    END   - Suggested changes for the entire website
*/

#vbf-content {
    min-height: calc((var(--vh, 1vh) * 100) - 175px);
    padding-top: 50px;
    margin-top: 121px;
}

.configuration-basket.configuration-basket--expandable {
    max-height: calc(100vh - 250px) !important;
}

.machine-configurator-wrapper {
    position: relative;
}

.content-padding {
    position: relative;
    width: calc(100% - 32px);
    max-width: 1100px;
}

.large-content-wrapper {
    min-height: 50vh;
}

#overview-scroll-determiner {
    position: fixed;
    left: 0px;
    top: 121px;
    height: calc(100vh - 121px);
    width: 0px;
}

select option {
    font-family: sans-serif;
}

.configuration-basket__configuration__action-menu__wrapper {
    z-index: 201;
}

@media (max-width: 1132px) {
    .header .top .center .logo {
        margin-left: 16px;
    }
}

@media (max-width: 768px) {
    .header .top-info .center .left.ctc:nth-child(1) {
        display: none;
    }
}

@media (max-width: 540px) {
    .header .top-info .center .left.ctc:nth-child(2) {
        display: none;
    }
}

@media (max-width: 500px) {
    .header .top .logo {
        display: none;
    }
}

@media (max-width: 384px) {
    .header .top-info .center .left.ctc:nth-child(3) {
        display: none;
    }
}